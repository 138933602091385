<section class="margem-top container">
  <div>
    <h3>Seleção de Perfil de Acesso</h3>
    <div class="br-table" *ngIf="perfil == ''">
      <div class="table-header">
        <div class="top-bar">
          <div class="table-title">Você deseja acessar como?</div>
        </div>
      </div>
      <div class="container-flex">
        <div class="cards">
          <div class="cards__items" (click)="escolherPerfil('contribuinte')">
            <div> 
              <img 
              class="imagem" 
              [src]="modoEscuro ? '../../../assets/images/icones/white-contribuinte.svg' : '../../../assets/images/icones/contribuinte.svg'" 
              alt="Imagem Contribuinte">
              <p class="cards__texto-maior mt-3">Contribuinte</p>
            </div>
          </div>
          <div class="cards__items" (click)="escolherPerfil('procurador')">
            <div>
              <img 
              class="imagem" 
              [src]="modoEscuro ? '../../../assets/images/icones/white-procurador.svg' : '../../../assets/images/icones/procurador.svg'" 
              alt="Imagem Procurador">
              <p class="cards__texto-maior">Procurador</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs ml-4" *ngIf="perfil == 'procurador' || perfil == 'socio'">
      <button 
        class="br-tag large"
        (click)="escolherPerfil('procurador')"
        [ngClass]="{ 'br-tag__selecionado': perfil == 'procurador' }"
        >Procuradores
      </button>
      <button 
        class="br-tag br-tag__selecionado large" 
        (click)="escolherPerfil('socio')"
        [ngClass]="{ 'br-tag__selecionado': perfil == 'socio' }"
        >Sócios
      </button>
    </div>
    <div class="br-table" *ngIf="perfil">
      <div class="table-header">
        <div class="top-bar">
          <div class="table-title">Por favor, com qual CPF/CNPJ deseja utilizar os serviços disponíveis no portal?</div>
        </div>
      </div>
      <table>
        <ng-container>
          <thead *ngIf="perfil == 'contribuinte'">
            <tr>
              <th scope="col">Nome/Razão Social</th>
              <th scope="col">Nome Fantasia</th>
              <th scope="col">CPF/CNPJ</th>
              <th scope="col">Inscrição Estadual</th>
              <th scope="col" *ngIf="tipoPessoa == 1">Relação</th>
              <th scope="col" *ngIf="tipoPessoa == 1">Início da Procuração</th>
              <th scope="col" *ngIf="tipoPessoa == 1">Fim da Procuração</th>
              <th scope="col" *ngIf="tipoPessoa == 2">Categoria do Estabelecimento</th>
            </tr>
          </thead>
          <thead *ngIf="perfil == 'procurador'">
            <tr>
              <th scope="col">Nome/Razão Social</th>
              <th scope="col">Nome Fantasia</th>
              <th scope="col">CPF/CNPJ</th>
              <th scope="col">Inscrição Estadual</th>
              <th scope="col">Relação</th>
              <th scope="col">Número da Procuração </th>
              <th scope="col">Início da Vigência</th>
              <th scope="col">Fim da Vigência</th>
            </tr>
          </thead>
          <thead *ngIf="perfil == 'socio'">
            <tr>
              <th scope="col">C.N.P.J</th>
              <th scope="col">Razão Social</th>
              <th scope="col">Inscrição Estadual</th>
              <th scope="col">Data início na sociedade</th>
              <th scope="col">Data fim na sociedade</th>
              <th scope="col">Responsável Legal</th>
            </tr>
          </thead>
        </ng-container>
        <tbody>
          <ng-container *ngIf="perfil =='contribuinte'">
            <tr class="cursor" *ngFor="let item of listaPaginada" (click)="contribuinteSelecionado(item)">
              <td>
                {{ 
                  item.dscRazaoSocial
                  ? item.dscRazaoSocial
                  : item.dscNomeCadastro
                }}  
              </td>
              <td>
                {{ 
                  item.dscNomeFantasia
                  ? item.dscNomeFantasia
                  : 'Não Informado'
                 }}
              </td>
              <td>
                {{ 
                  item.cpfCnpj
                  ? (item.cpfCnpj | mask: 'CPF_CNPJ') 
                  : (item.cadastro.cpfCnpj | mask: 'CPF_CNPJ')
                }}
              </td>
              <td>
                {{ item.dscIe ? item.dscIe : 'Não Informado' }}
              </td>
              <td *ngIf="tipoPessoa == 1">
                {{
                  ((item.contribuintes && item.contribuintes.length > 0) || item.cadastro)
                  ? 'Contribuinte'
                  : 'Não Contribuinte'
                }}
              </td>
              <td *ngIf="tipoPessoa == 1">N/A</td>
              <td *ngIf="tipoPessoa == 1">N/A</td>
              <td *ngIf="tipoPessoa == 2">{{ item.dscCategEstabelecimento}}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="perfil == 'procurador'">
            <tr class="cursor" *ngFor="let item of listaPaginada" (click)="procuradorSelecionado(item)">
              <td>{{ item.contribuinte.dscRazaoSocial}}</td>
              <td>{{ item.contribuinte.dscNomeFantasia }}</td>
              <td>{{ item.contribuinte.cadastro.cpfCnpj | mask: 'CPF_CNPJ' }}</td>
              <td>{{ item.contribuinte.dscIe ? item.contribuinte.dscIe : 'Não Informado'}}</td>
              <td>{{ item.tipoProcuracao.dscTipoProcuracao }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
              <td>{{ item.dataFim ? (item.dataFim | date: 'dd/MM/yyyy') : 'Não Informado' }}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="perfil == 'socio'">
            <tr class="cursor" *ngFor="let item of listaSocios" (click)="socioSelecionado(item)">
              <td>{{ item.contribuinte.cadastro.cpfCnpj | mask: 'CPF_CNPJ' }}</td>
              <td>{{ item.contribuinte.dscRazaoSocial }}</td>
              <td>{{ item.contribuinte.dscIe }}</td>
              <td>{{ item.datInicio | date: 'dd/MM/yyyy' }}</td>
              <td>{{ item.datFim ? (item.datFim | date: 'dd/MM/yyyy') : 'Não Informado' }}</td>
              <td>{{ item.administrador == 1 ? 'Sim' : 'Não' }}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="perfil == 'procurador' && !listaProcuradores">
            <td colspan="12" align="center">Sem dados</td>
          </ng-container>
          <ng-container *ngIf="perfil == 'socio' && !listaSocios">
            <td colspan="12" align="center">Sem dados</td>
          </ng-container>
        </tbody>
      </table>
      <div class="container-paginacao">
        <button class="br-button secondary mt-5 button-first" (click)="primeiraPagina()" [disabled]="pagina == 1 || pagina == 0" [ngClass]="{ disabled: pagina == 1, 'botao-proibido': pagina == 1}">Primeira</button>
        <button class="br-button secondary mt-5 button-first" (click)="paginaAnterior()" [disabled]="pagina == 1 || pagina == 0" [ngClass]="{ disabled: pagina == 1, 'botao-proibido': pagina == 1}">Anterior</button>
        <p class="mt-5">{{ pagina }} de {{ quantidadePaginas }}</p>
        <button class="br-button secondary mt-5  button-first" (click)="proximaPagina()" [disabled]="pagina == quantidadePaginas || quantidadePaginas == 0" [ngClass]="{ disabled: pagina == quantidadePaginas, 'botao-proibido': pagina == quantidadePaginas }">Próxima</button>
        <button class="br-button secondary mt-5 button-first" (click)="ultimaPagina()" [disabled]="pagina == quantidadePaginas || quantidadePaginas == 0" [ngClass]="{ disabled: pagina == quantidadePaginas, 'botao-proibido': pagina == quantidadePaginas }">Última</button>
      </div>
      <button class="br-button secondary mt-5 mr-4 posicao-botao" type="button" (click)="voltarSelecao()" [disabled]="retornarDesativado">Retornar</button>
    </div>
  </div>
</section>