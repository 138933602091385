import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AtendimentoDecService } from "src/app/services/atendimento-dec";
import Swal from "sweetalert2";

@Component({
  selector: 'app-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss']
})
export class FaleConoscoComponent {

  formulario: FormGroup;
  anexos: { nomeArquivo: string; arquivo: string }[] = [];
  relacaoSolicitanteContribuinte: string = '';
  motivoSolicitacaooAtendimento: string = '';
  file: File;
  files: any = [];
  fileName: string;
  base64File: any = [];
  liberado: boolean = false;
  loading: boolean = false;

  constructor(private fb: FormBuilder, private atendimentoDecService: AtendimentoDecService, private router: Router) {
    this.formulario = this.fb.group({
      dscCpfCnpjSolicitante: ['', Validators.required],
      dscNomeRazaoSocialSolicitante: ['', Validators.required],
      emailSolicitante: ['', Validators.required],
      telefoneSolicitante: [''],
      dscCpfCnpjContribuinte: ['', Validators.required],
      dscNomeRazaoSocialContribuinte: ['', Validators.required],
      relacaoSolicitanteContribuinte: [''],
      motivoSolicitacaooAtendimento: [''],
      txtMensagem: [''],
      anexo: ['']
    });
  }

  onSelectionChange(relacao: string) {
    this.relacaoSolicitanteContribuinte = relacao;
  }

  onMotivoChange(motivo: string) {
    this.motivoSolicitacaooAtendimento = motivo;
  }

  onBuscarContribuinte() {
    const cpfCnpj = this.formulario.get('dscCpfCnpjContribuinte')?.value;

    if (cpfCnpj) {
      this.atendimentoDecService.pesquisarContribuinte(cpfCnpj).subscribe({
        next: (data) => {
          const contribuinte = data[0];
          this.formulario.patchValue({
            dscNomeRazaoSocialContribuinte: contribuinte.dscNomeCadastro
          });
        },
        error: (err) => {
        }
      });
    }
  }

  anexo(event: any) {
    const input = event.target; 
  
    for (let i = 0; i < input.files.length; i++) {
      const file = input.files[i];
      const reader = new FileReader();
  
      reader.onload = () => {
        const base64String = reader.result as string;
        
        this.anexos.push({
          nomeArquivo: file.name,
          arquivo: base64String.split(',')[1], 
        });
      };
  
      reader.readAsDataURL(file); 
    }
    
    input.value = '';
  } 

  deletarArquivo(index: number) {
    this.anexos.splice(index, 1);
  }
  
  enviarFormulario() {
    this.loading = true;
    if (this.formulario.invalid) {
      this.formulario.markAllAsTouched();
      Swal.fire({
        icon: 'warning',
        text: 'Por favor, preencha todos os campos obrigatórios!'
      });
      return;
    }

    if (this.liberado) return;
    this.liberado = true;

    const obj = {
      solicitante: {
        dscCpfCnpj: this.formulario.get('dscCpfCnpjSolicitante')?.value,
        dscNomeRazaoSocial: this.formulario.get('dscNomeRazaoSocialSolicitante')?.value,
        email: this.formulario.get('emailSolicitante')?.value,
      },
      contribuinte: {
        dscCpfCnpj: this.formulario.get('dscCpfCnpjContribuinte')?.value,
        dscNomeRazaoSocial: this.formulario.get('dscNomeRazaoSocialContribuinte')?.value,
      },
      relacaoSolicitanteContribuinte: this.relacaoSolicitanteContribuinte,
      motivoSolicitacaooAtendimento: this.motivoSolicitacaooAtendimento,
      txtMensagem: this.formulario.get('txtMensagem')?.value,
      telefone: this.formulario.get('telefoneSolicitante')?.value,
      anexos: this.anexos
    };

    this.atendimentoDecService.enviarSolicitacao(obj).subscribe(
      res => {
        Swal.fire({
          icon: 'success',
          text: 'Dados enviados com sucesso.'
        }).then(() => {
          this.router.navigate(['/home']);
        });
        this.liberado = false;
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: 'Ops! Ocorreu um erro para enviar o formulário.'
        });
        this.loading = false;
        this.liberado = true;
      }
    );
  }
}
