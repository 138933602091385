<form [formGroup]="formulario" (ngSubmit)="enviarFormulario()">

  <div class="container">
    <h1>Solicitação de Atendimento ao DEC</h1>
  </div>
  <section class="margin-top container">
    <div class="container-flex">
      <div class="servicos-texto">Solicitante</div>
      <div class="row">
        <div class="col-6">
          <div class="br-input">
            <label>CPF/CNPJ</label>
            <input formControlName="dscCpfCnpjSolicitante" id="dscCpfCnpjSolicitante" name="dscCpfCnpjSolicitante"
              placeholder="CPF/CNPJ" type="text" mask="CPF_CNPJ" />
            <p class="mensagem-erro"
              *ngIf="formulario.controls.dscCpfCnpjSolicitante.invalid && formulario.controls.dscCpfCnpjSolicitante.touched">
              Campo obrigatório
            </p>
          </div>
        </div>
        <div class="col-6">
          <div class="br-input">
            <label>Nome / Razão Social</label>
            <input formControlName="dscNomeRazaoSocialSolicitante" id="dscNomeRazaoSocialSolicitante"
              name="dscNomeRazaoSocialSolicitante" placeholder="Nome" type="text" maxlength="100" />
            <p class="mensagem-erro"
              *ngIf="formulario.controls.dscNomeRazaoSocialSolicitante.invalid && formulario.controls.dscNomeRazaoSocialSolicitante.touched">
              Campo obrigatório
            </p>
          </div>
        </div>
        <div class="col-6 mt-2">
          <div class="br-input">
            <label>E-mail</label>
            <input formControlName="emailSolicitante" id="emailSolicitante" name="emailSolicitante" placeholder="E-mail"
              type="email" maxlength="100" />
            <p class="mensagem-erro"
              *ngIf="formulario.controls.emailSolicitante.invalid && formulario.controls.emailSolicitante.touched">
              Campo obrigatório
            </p>
          </div>
        </div>
        <div class="col-6 mt-2">
          <div class="br-input">
            <label>Telefone</label>
            <input formControlName="telefoneSolicitante" id="" name="telefoneSolicitante" placeholder=" (99) 99999-9999"
              type="tel" mask="99 99999 9999" />
          </div>
        </div>
      </div>

      <div class="servicos-texto mt-4">Contribuinte</div>
      <div class="row">
        <div class="col-6">
          <div class="br-input">
            <label>CPF/CNPJ</label>
            <input formControlName="dscCpfCnpjContribuinte" id="dscCpfCnpjContribuinte" name="dscCpfCnpjContribuinte"
              placeholder="CPF/CNPJ" type="text" mask="CPF_CNPJ" (blur)="onBuscarContribuinte()" />
            <p class="mensagem-erro"
              *ngIf="formulario.controls.dscCpfCnpjContribuinte.invalid && formulario.controls.dscCpfCnpjContribuinte.touched">
              Campo obrigatório
            </p>
          </div>
        </div>
        <div class="col-6">
          <div class="br-input">
            <label>Nome / Razão Social</label>
            <input formControlName="dscNomeRazaoSocialContribuinte" id="dscNomeRazaoSocialContribuinte"
              name="dscNomeRazaoSocialContribuinte" placeholder="Nome" type="text" maxlength="100" />
            <p class="mensagem-erro"
              *ngIf="formulario.controls.dscNomeRazaoSocialContribuinte.invalid && formulario.controls.dscNomeRazaoSocialContribuinte.touched">
              Campo obrigatório
            </p>
          </div>
        </div>
      </div>

      <!-- "Relacao Solicitante Contribuinte" -->

      <div class="servicos-texto mt-4">Relação do Solicitante com o contribuinte:</div>
      <div class="div-checkbox">
        <input class="" id="socio" name="relacaoSolicitante" type="radio" (change)="onSelectionChange('Sócio')" />
        <label class="texto-input">Sócio</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="legalAdm" name="relacaoSolicitante" type="radio"
          (change)="onSelectionChange('Representante Legal/Administrador')" />
        <label class="texto-input">Representante Legal/Administrador</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="procurador" name="relacaoSolicitante" type="radio"
          (change)="onSelectionChange('Procurador')" />
        <label class="texto-input">Procurador(Contador/Advogado/Outros)</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="operador" name="relacaoSolicitante" type="radio" (change)="onSelectionChange('Operador')" />
        <label class="texto-input">Operador de Acesso aos Serviços da SEFAZ</label>
      </div>

      <!-- "Motivo Solicitacaoo Atendimento" -->

      <div class="servicos-texto mt-4">Motivo da Solicitação de Atendimento:</div>
      <div class="div-checkbox">
        <input class="" id="motivo1" name="motivoSolicitacao" type="radio"
          (change)="onMotivoChange('Não estou conseguindo acessar com o e-CNPJ do Contribuinte.')" />
        <label class="texto-input">Não estou conseguindo acessar com o e-CNPJ do Contribuinte.</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="motivo2" name="motivoSolicitacao" type="radio"
          (change)="onMotivoChange('Sou empresa com participação em Contribuinte, acessei com o meu e-CNPJ, e não listou o Contribuinte que temos participação e suas filiais.')" />
        <label class="texto-input">Sou empresa com participação em Contribuinte, acessei com o meu e-CNPJ,
          e não listou o Contribuinte que temos participação e suas filiais.</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="motivo3" name="motivoSolicitacao" type="radio"
          (change)="onMotivoChange('Sou Procurador (Contador/Advogado/Outros). Acessei com meu CPF/e-CNPJ, mas a procuração outorgada pelo Contribuinte não está listada')" />
        <label class="texto-input">Sou Procurador (Contador/Advogado/Outros). Acessei com meu CPF/e-CNPJ,
          mas a procuração outorgada pelo Contribuinte não está listada</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="motivo4" name="motivoSolicitacao" type="radio"
          (change)="onMotivoChange('Sou sócio de um contribuinte. Acessei com meu CPF, mas não foram listados o contribuinte e suas filiais nas quais tenho sociedade')" />
        <label class="texto-input">Sou sócio de um contribuinte. Acessei com meu CPF, mas não
          foram listados o contribuinte e suas filiais nas quais tenho sociedade</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="motivo5" name="motivoSolicitacao" type="radio"
          (change)="onMotivoChange('Sou representante legal/administrador. Acessei com meu CPF, mas não foram listados o contribuinte e suas filiais que represento/administro.')" />
        <label class="texto-input">Sou representante legal/administrador. Acessei com meu CPF,
          mas não foram listados o contribuinte e suas filiais que represento/administro.</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="motivo6" name="motivoSolicitacao" type="radio"
          (change)="onMotivoChange('Consegui acesso, mas não apresentou a Caixa Postal Eletrônica.')" />
        <label class="texto-input">Consegui acesso, mas não apresentou a Caixa Postal Eletrônica.</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="motivo7" name="motivoSolicitacao" type="radio"
          (change)="onMotivoChange('Consegui acesso, mas não conseguir aderir à Caixa Postal Eletrônica.')" />
        <label class="texto-input">Consegui acesso, mas não conseguir aderir à Caixa Postal Eletrônica.</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="motivo8" name="motivoSolicitacao" type="radio"
          (change)="onMotivoChange('Acesso a Caixa Postal, mas não consigo responder ao comunicado recebido.')" />
        <label class="texto-input">Acesso a Caixa Postal, mas não consigo responder ao comunicado recebido.</label>
      </div>
      <div class="div-checkbox">
        <input class="" id="motivo9" name="motivoSolicitacao" type="radio" (change)="onMotivoChange('Outros')" />
        <label class="texto-input">Outros</label>
      </div>

      <div class="servicos-texto mt-4">Breve Relato:</div>
      <div>
        <textarea id="txtMensagem" formControlName="txtMensagem" rows="10" cols="100" maxlength="500"></textarea>
      </div>

      <div>
        <div class="servicos-texto mt-4">Anexos:</div>
        <span class="btn btn-primary btn-file">
          PDF / Imagens
          <input
            id="file-input"
            type="file"
            accept="application/pdf,image/jpeg,image/png"
            multiple
            (change)="anexo($event)"
            #fileUpload
          />
        </span>
        <div class="file-upload">
          <div *ngFor="let anexo of anexos; let i = index" class="file-item">
            {{ anexo.nomeArquivo }}
            <fa-icon class="lixeira" [icon]="['fas', 'trash']" (click)="deletarArquivo(i)"></fa-icon>
          </div>
        </div>
      </div> 
    </div>
  </section>

  <div class="botoes">
    <div class="div-botao mt-4">
      <button class="br-button secondary posicao-botao mr-3" type="button" routerLink="/home">Retornar</button>
      <button class="br-button primary posicao-botao" type="submit" [disabled]="liberado">Solicitar Atendimento</button>
    </div>
  </div>

</form>