import { IntegracaoComponent } from './features/integracao/integracao.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './features/inicio/inicio.component';
import { PreAcessoComponent } from './features/pre-acesso/pre-acesso.component';

import { CaixaPostalComponent } from './features/caixa-postal/caixa-postal.component';
import { CaixaPostalComunicadoComponent } from './features/caixa-postal/caixa-postal-comunicado/caixa-postal-comunicado.component';
import { SelecaoRepresentanteComponent } from './features/selecao-representante/selecao-representante.component';
import { ProcuracaoComponent } from './features/procuracao/procuracao.component';
import { ProcuracaoOutorgarComponent } from './features/procuracao/procuracao-outorgar/procuracao-outorgar.component';
import { AuthGuard } from './features/core/auth.guard';
import { LoginGuard } from './features/core/login.guard';
import { DuvidasFrequentesComponent } from './features/home/duvidas-frequentes/duvidas-frequentes.component';
import { PerfilContribuinteComponent } from './features/perfil-contribuinte/perfil-contribuinte.component';
import { MeuPerfilComponent } from './features/meu-perfil/meu-perfil.component';
import { ValidarComponent } from './features/validar/validar.component';
import { AtendimentoDecComponent } from './features/home/atendimento-dec/atendimento-dec.component';
import { FaleConoscoComponent } from './features/fale-conosco/fale-conosco.component';

const routes: Routes = [
  { 
    path: '',
    redirectTo: 'home', 
    pathMatch: 'full',
  },
  { 
    path: 'pre-acesso', 
    component: PreAcessoComponent, 
    data: { breadcrumb: 'Pré Acesso' },
    canActivate: [LoginGuard]
  },
  { 
    path: 'integracao', 
    component: IntegracaoComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'duvidas-frequentes',
    component: DuvidasFrequentesComponent,
    data: { breadcrumb: 'Dúvidas Frequentes' },
    canActivate: [LoginGuard]
  },
  {
    path: 'validar',
    component: ValidarComponent,
    data: { breadcrumb: 'Validar' }
  },
  {
    path: 'atendimento-dec',
    component: AtendimentoDecComponent,
    data: { breadcrumb: 'Atendimento ao DEC' },
    canActivate: [LoginGuard]
  },
  { 
    path: 'selecao-representante', 
    component: SelecaoRepresentanteComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'inicio', 
    component: InicioComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'refis', 
    canActivate: [AuthGuard],
    data: { breadcrumb: 'REFIS' },
    loadChildren: () => import('./features/refis/refis.module').then(m => m.RefisModule),
  },
  { 
    path: 'caixa-postal', 
    component: CaixaPostalComponent, 
    data: { breadcrumb: 'Caixa Postal Eletrônica' },
    canActivate: [AuthGuard]
  },
  { 
    path: 'caixa-postal/comunicado/:comunicado', 
    component: CaixaPostalComunicadoComponent, 
    data: { breadcrumb: 'Comunicação Eletrônica' },
    canActivate: [AuthGuard]
  },
  { 
    path: 'procuracao', 
    component: ProcuracaoComponent, 
    data: { breadcrumb: 'Procuração Eletrônica' },
    canActivate: [AuthGuard]
  },
  { 
    path: 'procuracao-outorgar', 
    component: ProcuracaoOutorgarComponent, 
    data: { breadcrumb: 'Outorgar Procuração' },
    canActivate: [AuthGuard]
  },
  {
    path: 'cadastro',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/cadastro/cadastro.module').then(m => m.CadastroModule)
  },
  {
    path: 'perfil-contribuinte',
    component: PerfilContribuinteComponent,
    data: { breadcrumb: 'Perfil do Contribuinte'},
    canActivate: [AuthGuard]
  },
  {
    path: 'meu-perfil',
    component: MeuPerfilComponent,
    data: { breadcrumb: 'Meu Perfil'},
    canActivate: [AuthGuard]
  },
  {
    path: 'fale-conosco',
    component: FaleConoscoComponent,
    data: { breadcrumb: 'Fale Conosco'},
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled'
  })
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
