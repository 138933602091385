import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { TokenService } from "src/app/services/token.service";
import Swal from "sweetalert2";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.tokenService.hasToken()) {
      const token = this.tokenService.getToken();
      req = req.clone({
        setHeaders: {
          'Authorization': token
        }
      });
    }
    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          Swal.fire({
            icon: 'warning',
            text: 'A sessão expirou, faça o login novamente.'
          }).then(() => {
            this.tokenService.removeToken();
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "https://sso.acesso.gov.br/logout";
            window.location.href = "https://dfe.sefaz.to.gov.br";
          })
        };
      }
      return throwError(err);
    }));
  }
}